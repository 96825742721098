import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const theme = merge(originalTheme, {
  fonts: {
    body: `"Roboto"`,
  },
  styles: {
    p: {
      fontSize: [1, 1, 1],
    },
    ul: {
      li: {
        fontSize: [1, 1, 1],
      }
    },
    ol: {
      li: {
        fontSize: [1, 1, 1],
      }
    },
    h1: {
      variant: `text.heading`,
      fontSize: [5, 5, 5, 5],
      mt: 4,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [4, 4, 4, 4],
      mt: 4,
    },
    h3: {
      variant: `text.heading`,
      fontSize: [3, 3, 3, 3],
      mt: 4,
    },
    h4: {
      variant: `text.heading`,
      fontSize: [2, 2, 2, 2],
      mt: 3,
    },
    h5: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 1],
      mt: 3,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
      mb: 2,
    },
  },
});

export default theme;